export const countryMapping = () => {
  const country = [
    // Vietnam
    {
      code: 'vn',
      name: 'Vietnam',
      primaryLanguage: 'Vietnamese',
      secondaryLanguage: 'English',
      locale: 'vi-vn',
      BASE_URL: {
        dev: 'https://dev.api.vn.academy-preprod.amway.com/mw',
        qa: 'https://qa.api.vn.academy-preprod.amway.com/mw',
        prod: 'https://api.academy.amway.com.vn/mw',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.tw.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.tw.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.tw.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.vn.academy-preprod.amway.com/dashboard',
        qa: 'https://qa.admin.vn.academy-preprod.amway.com/dashboard',
        prod: 'https://admin.academy.amway.com.vn/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1uaupq9szMx9bs0h8',
        qa: '0oa1ur7k15rc3ozAg0h8',
        prod: '0oa1vycrc3kqTdEdf0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.vn.academy-preprod.amway.com',
        'https://qa.admin.vn.academy-preprod.amway.com',
        'https://admin.academy.amway.com.vn',
      ],
    },

    // North America
    {
      code: 'ana',
      name: 'North America',
      primaryLanguage: 'English',
      secondaryLanguage: 'English',
      locale: 'en-us',
      BASE_URL: {
        dev: 'https://dev.api.ana.academy-preprod.amway.com/mw',
        qa: 'https://qa.api.ana.academy-preprod.amway.com/mw',
        prod: ' https://api.academy.amway.com/mw',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.ana.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.ana.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.ana.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.ana.academy-preprod.amway.com/dashboard',
        qa: 'https://qa.admin.ana.academy-preprod.amway.com/dashboard',
        prod: 'https://admin.academy.amway.com/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1u4wugdeQJUkQs0h8',
        qa: '0oa1ur8pqpdZlek4m0h8',
        prod: '0oa1wghvi0le72s0m0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.ana.academy-preprod.amway.com',
        'https://qa.admin.ana.academy-preprod.amway.com',
        'https://admin.academy.amway.com',
      ],
    },

    // India
    {
      code: 'in',
      name: 'India',
      primaryLanguage: 'English',
      secondaryLanguage: 'English',
      locale: 'en-in',
      BASE_URL: {
        dev: 'https://dev.api.in.academy-preprod.amway.com/mw',
        qa: 'https://qa.api.in.academy-preprod.amway.com/mw',
        prod: 'https://api.academy.amway.in/mw',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.in.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.in.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.in.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.in.academy-preprod.amway.com/dashboard',
        qa: 'https://qa.admin.in.academy-preprod.amway.com/dashboard',
        prod: 'https://admin.academy.amway.in/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1p8u8aqtR2Ske80h8',
        qa: '0oa1rpysehw1IDIxr0h8',
        prod: '0oa1rvcfyu8Tf03sz0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.in.academy-preprod.amway.com',
        'https://qa.admin.in.academy-preprod.amway.com',
        'https://admin.academy.amway.in',
      ],
    },

    // Taiwan
    {
      code: 'tw',
      name: 'Taiwan',
      primaryLanguage: 'English',
      secondaryLanguage: 'English',
      locale: 'en-tw',
      BASE_URL: {
        dev: 'https://dev.api.tw.academy.amway.com/mw',
        qa: 'https://qa.api.tw.academy-preprod.amway.com/mw',
        prod: 'https://api.academy.amway.com.tw/mw',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.tw.academy.amway.com/dashboard',
        qa: 'https://qa.admin.tw.academy-preprod.amway.com/dashboard',
        prod: 'https://admin.academy.amway.com.tw/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1oqgzkgdxxEXTi0h8',
        qa: '0oa1ov5i6oadXNvYf0h8',
        prod: '0oa1ozatlq7ZVrjgw0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.tw.academy.amway.com',
        'https://qa.admin.tw.academy-preprod.amway.com',
        'https://admin.academy.amway.com.tw',
      ],
    },
    // Latam
    {
      code: 'es',
      name: 'LATAM',
      primaryLanguage: 'Spanish',
      secondaryLanguage: 'Portuguese',
      locale: 'es-419',
      BASE_URL: {
        dev: 'https://dev.api.academy-preprod.amway.com.mx/mw',
        qa: 'https://qa.api.academy-preprod.amway.com.mx/mw',
        prod: 'https://api.academy.amway.com.mx/mw',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.ana.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.ana.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.ana.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.academy-preprod.amway.com.mx/dashboard',
        qa: 'https://qa.admin.academy-preprod.amway.com.mx/dashboard',
        prod: 'https://admin.academy.amway.com.mx/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1xtwe39uxWqEHH0h8',
        qa: '0oa1y37w1w7RMwhx80h8',
        prod: '0oa1yuy2vfsVHsPYI0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.academy-preprod.amway.com.mx',
        'https://qa.admin.academy-preprod.amway.com.mx',
        'https://admin.academy.amway.com.mx',
      ],
    },
    //ESAN
    {
      code: 'en',
      name: 'ESAN',
      primaryLanguage: 'English',
      secondaryLanguage: 'English',
      locale: 'en-us',
      BASE_URL: {
        dev: 'https://dev.api.esan.academy-preprod.amway.com/mw',
        qa: 'https://qa.api.esan.academy-preprod.amway.com/mw',
        prod: 'https://api.academy.amway.it/mw',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.it.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.it.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.it.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.esan.academy-preprod.amway.com/dashboard',
        qa: 'https://qa.admin.esan.academy-preprod.amway.com/dashboard',
        prod: 'https://admin.academy.amway.it/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1yg6d898HQKTEN0h8',
        qa: '0oa1yg68x1pKqGZcj0h8',
        prod: '0oa1z28n32skXSqVG0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.esan.academy-preprod.amway.com',
        'https://qa.admin.esan.academy-preprod.amway.com',
        'https://admin.academy.amway.it',
      ],
    },
    //JAPAN Configs
    {
      code: 'jp',
      name: 'JAPAN',
      primaryLanguage: 'Japanese',
      secondaryLanguage: 'English',
      locale: 'ja-jp',
      BASE_URL: {
        dev: ' https://dev.api.academy-preprod.amwaylive.com/mw',
        qa: ' https://qa.api.academy-preprod.amwaylive.com/mw',
        prod: 'https://api.academy.amwaylive.com/mw',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.tw.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.tw.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.tw.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.academy-preprod.amwaylive.com/dashboard',
        qa: 'https://qa.admin.academy-preprod.amwaylive.com/dashboard',
        prod: 'https://admin.academy.amwaylive.com/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa1z28odvnqRa7wH0h8',
        qa: '0oa1z72crhhZBB7BR0h8',
        prod: '0oa1zlpf78hFDCU0m0h8',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.academy-preprod.amwaylive.com',
        'https://qa.admin.academy-preprod.amwaylive.com',
        'https://admin.academy.amwaylive.com',
      ],
    },
    //Thailand Configs
    {
      code: 'th',
      name: 'THAILAND',
      primaryLanguage: 'Thai',
      secondaryLanguage: 'English',
      locale: 'th-th',
      BASE_URL: {
        dev: 'https://dev.api.academy-preprod.amway.co.th/mw',
        qa: 'https://qa.api.academy-preprod.amway.co.th/mw',
        // prod: 'https://api.academy.amwaylive.com',
      },
      BASE_CUSTOM_UG_URL: {
        dev: 'https://services-test.tw.creators.amwayglobal.com/groups/v1',
        qa: 'https://services-stage.tw.creators.amwayglobal.com/groups/v1',
        prod: 'https://services.tw.creators.amwayglobal.com/groups/v1',
      },
      REDIRECT_URI: {
        dev: 'https://dev.admin.academy-preprod.amway.co.th/dashboard',
        qa: 'https://qa.admin.academy-preprod.amway.co.th/dashboard',
        // prod: 'https://admin.academy.amwaylive.com/dashboard',
      },
      CLIENT_ID: {
        dev: '0oa20i2q2u5LjyEJ70h8',
        qa: '0oa20zv45f685aKKD0h8',
        prod: '',
      },
      OIDC_ISSUER: 'https://amway.okta.com/oauth2/aus1h78lyb8aJ7EBp0h8',
      urls: [
        'https://dev.admin.academy-preprod.amway.co.th',
        'https://qa.admin.academy-preprod.amway.co.th',
        // 'https://admin.academy.amwaylive.com',
      ],
    },
  ];

  return country;
};